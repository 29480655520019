<template>
  <page-layout>
    <div class="wrapper requirment-spec">
      <div class="title-wrap relative">
        <h3 class="page-titles">
          Requirement specifications
        </h3>
        <p>
          Create and manage requirement specifications. <label
            for="how-does-it-work-modal-req1"
            class="link modal-button"
          >
            How does it work?
          </label>
        </p>
        <div class="profile">
          <the-profile class="print:hidden" />
        </div>

        <div class="flex mt-10">
          <router-link
            :to="{name: 'CreateRequirementSpecification'}"
            class="btn-secondary"
          >
            Create new
          </router-link>
        </div>
      </div>

      <table
        v-if="requirementSpecifications"
        class="table-auto mt-10 border-collapse border border-slate-400 text-base w-full"
      >
        <thead>
          <tr>
            <th class="border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">
              Name
            </th>
            <th class="border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">
              Created
            </th>
            <th class="border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="requirementSpecification in requirementSpecifications"
            :key="requirementSpecification.id"
          >
            <td class="border border-slate-300 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
              <span class="text-lg"> {{ requirementSpecification.name }}</span>
            </td>
            <td class="border border-slate-300 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
              <span class="text-lg">{{ new Date(requirementSpecification.createdAt).toLocaleDateString() }}</span>
            </td>
            <td class="border border-slate-300 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
              <div class="flex">
                <router-link
                  class="cursor-pointer"
                  :to="{ name: 'EditRequirementSpecification', params: {id: requirementSpecification.id} }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </router-link>
                <label
                  for="delete-modal"
                  class="cursor-pointer"
                  @click="deletingRequirementSpecification = requirementSpecification"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 ml-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <input
          type="checkbox"
          id="how-does-it-work-modal-req1"
          class="modal-toggle"
        >
        <div class="modal">
          <div class="max-w-6xl modal-box p-8">
            <h3 class="text-lg font-semibold mb-8">
              Requirement matching method
            </h3>
            <p>
              Be aware!
            </p>
            <p>
              The requirement specification focus on what is actually required in terms of <strong>cognitive abilities.</strong> Beyond titles, as several assignments and job roles may have similar cognitive requirements. It is a tool for you as a recruiter or user of the test result.
            </p>
            <br>
            <p>
              You can chose to show the specification in the report or not. First in the scoreboard where it automatically is matched with candidates actual performance in a <strong>matching index</strong>.
            </p>
            <p>
              Secondly in the report when analyzing and matching candidates to the normal population or to each other.
            </p>
            <br>
            <p>
              Remember that this is your subjective view. It is hard to judge cognitive abilities and you should view it rather as one of several benchmarks to try and understand the candidates.
            </p>
            <br>
            <p>
              As the specification is related to the actual performance be aware that <strong>the results are normally distributed in the population</strong>. This means that most people are somewhere in the middle while some are in the lower or higher ends of the scales. There are no “good” or “bad”, just different profiles that are optimal for different assignments, challenges and situations.
            </p>
            <br>
            <p>
              Take notice of this distribution when you specify your requirement, as to <strong>not put to high demands in an area that is not important for your working environment, or the position you are hiring for.</strong> As an example, the highest requirement is only represented by 4 % of the population.
            </p>
            <!-- <p>
              The requirement matching method is a way to convert the requirements placed on a candidate, from workplace demands to cognitive ability. The functions behind it evaluates the occurrence and importance of the ability to create a desirable profile to match against.
            </p> -->
            <div class="modal-action">
              <label
                for="how-does-it-work-modal-req1"
                class="btn-outline-small"
              >Close</label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <input
          type="checkbox"
          id="delete-modal"
          class="modal-toggle"
        >
        <div class="modal">
          <div class="modal-box">
            <h4 v-if="deletingRequirementSpecification">
              Delete {{ deletingRequirementSpecification.name }}
            </h4>
            <p v-if="deletingRequirementSpecification">
              Created at: {{ new Date(deletingRequirementSpecification.createdAt).toLocaleDateString() }}
            </p>
            <div class="modal-action">
              <label
                for="delete-modal"
                class="btn"
              >Cancel</label>
              <label
                for="delete-modal"
                class="btn btn-outline"
                @click="deleteRequirementSpecification(deletingRequirementSpecification.id)"
              >Yes</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>

import { mapGetters } from 'vuex'

import PageLayout from '@/layouts/PageLayout.vue'
import TheProfile from '@/components/TheProfile.vue'

export default {
  components: {
    PageLayout,
    TheProfile
  },
  data () {
    return {
      deletingRequirementSpecification: null
    }
  },

  computed: {
    ...mapGetters({ requirementSpecifications: 'REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications' })
  },
  async created () {
    this.$store.dispatch('REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications')
  },
  methods: {
    async deleteRequirementSpecification (id) {
      const data = await this.$api.deleteRequirementSpecification(id)

      if (data.message === 'success') {
        await this.$store.dispatch('REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications')
      }
    }
  }
}
</script>

<style>

</style>
